import ApiClient from '@/api/ApiClient';
import { NurseryUserResponse } from 'chaild-api/src/component/nursery/user/types';

export default class ApiNurseryUser {
  // 園の日誌
  public static async listStaffs(
    nurseryId: number,
    input: {
      date?: string;
      diaryId?: number;
      diaryType?: 'class' | 'school' | 'hours' | 'nap' | 'Test';
    }
  ) {
    const response = await ApiClient.get(`/nursery/${nurseryId}/user`, input);
    if (response && response.users) {
      return response.users as NurseryUserResponse[];
    }
    return [];
  }
}