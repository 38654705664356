import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiInvoice from "@/api/ApiInvoice";
import {
  InvoiceResponse,
  NurseryHoursFeeInvoiceResponse,
  SearcNurseryFeeResponse,
} from "chaild-api/lib";

export type InvoiceStatus =
  | "applied"
  | "canceled"
  | "salesSettled"
  | "invoiceSettled";

export interface InvoiceState {
  invoiceList: InvoiceResponse[];
  invoiceDetail: NurseryHoursFeeInvoiceResponse | null;
  status: InvoiceStatus | null;
  feeList: SearcNurseryFeeResponse[];
}

@Module({ dynamic: true, store, name: "invoice", namespaced: true })
class InvoiceModule extends VuexModule implements InvoiceState {
  public invoiceList: InvoiceResponse[] = [];
  public invoiceDetail: NurseryHoursFeeInvoiceResponse | null = null;
  public status: InvoiceStatus | null = null;
  public feeList: SearcNurseryFeeResponse[] = [];

  @Action({ rawError: true })
  public async listInvoice(input: {
    nurseryId: number;
    classId?: number;
    month?: number;
    year?: number;
  }) {
    const response = await ApiInvoice.listInvoice(input);
    if (response) {
      this.setInvoiceList(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async getInvoice(input: {
    nurseryId: number;
    childId: number;
    month: number;
    year: number;
  }) {
    const response = await ApiInvoice.getInvoice(input);
    if (response) {
      this.setInvoiceDetail(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async getInvoiceStatus(input: {
    nurseryId: number;
    month: number;
    year: number;
  }) {
    const response = await ApiInvoice.getInvoiceStatus(input).catch((e) =>
      console.warn(e)
    );
    if (response) {
      this.setInvoiceStatus(response.status);
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateInvoiceStatus(input: {
    nurseryId: number;
    month: number;
    year: number;
    status: "applied" | "canceled";
  }) {
    const response = await ApiInvoice.updateInvoiceStatus(input).catch((e) =>
      console.warn(e)
    );
    if (response) {
      this.setInvoiceStatus(response.status);
      return response;
    }
  }

  // 認定情報取得
  @Action({ rawError: true })
  public async getFee(input: {
    nurseryId: number;
    classId?: string;
    limit?: number;
    skip?: number;
  }) {
    const response = await ApiInvoice.getFee(input);
    if (response) {
      this.setFeeList(response);
    }
  }

  @Mutation
  public setInvoiceList(value: InvoiceResponse[]) {
    this.invoiceList = value;
  }

  @Mutation
  public setInvoiceDetail(value: NurseryHoursFeeInvoiceResponse) {
    this.invoiceDetail = value;
  }

  @Mutation
  public setInvoiceStatus(value: InvoiceStatus) {
    this.status = value;
  }

  @Mutation
  public setFeeList(value: SearcNurseryFeeResponse[]) {
    this.feeList = value;
  }
}

export const invoiceModule = getModule(InvoiceModule);
