import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import ApiStaff from "@/api/ApiStaff";
import { StaffInfo, StaffInfoDetailResponse } from "chaild-api/lib";

export interface StaffState {
  staff: StaffInfo[];
  staffDetail: StaffInfoDetailResponse | null;
  staffIds: number[]; // user_nurseries.shift_orderの昇順
  retireCheck: boolean; // 退職者の表示
}

@Module({ dynamic: true, store, name: "staff", namespaced: true })
class StaffModule extends VuexModule implements StaffState {
  public staff: StaffInfo[] = [];
  public staffDetail: StaffInfoDetailResponse | null = null;
  public staffIds: number[] = [];
  public retireCheck = false;

  @Action({ rawError: true })
  public async listStaff(input: { nurseryId: string; check?: boolean }) {
    const response = await ApiStaff.listStaff(input);
    if (response) {
      this.setStaff(response.staffs);
      return response;
    }
  }

  @Action({ rawError: true })
  public async getStaff(input: { nurseryId: string; userNurseryId: string }) {
    const response = await ApiStaff.getStaff(input);
    if (response) {
      this.setStaffDetail(response);
      return response;
    }
  }

  // スタッフ並び替え
  @Action({ rawError: true })
  public async sortStaffs(nurseryId: number) {
    const response = await ApiStaff.sortStaffs({
      nurseryId: nurseryId,
      userIds: this.staffIds,
    })
    if (response) {
      const users = response?.users || [];
      this.setStaffIds(users.map((staff) => staff.userId))
    }
  }

  @Mutation
  public setStaff(value: StaffInfo[]) {
    this.staff = value;
  }

  @Mutation
  public setStaffDetail(value: StaffInfoDetailResponse) {
    this.staffDetail = value;
  }

  @Mutation
  public setStaffIds(value: number[]) {
    this.staffIds = value;
  }

  @Mutation
  public setCheck(retireCheck: boolean) {
    this.retireCheck = retireCheck;
  }
}

export const staffModule = getModule(StaffModule);
