
import Vue from "vue";
import draggable from "vuedraggable";
import PageTitle from "@/components/atoms/PageTitle.vue";
// import StaffTableContainer from "@/containers/StaffTableContainer.vue";
import { staffModule } from "@/store/dataModules/staffModule";
import LocalDataService from "@/service/LocalDataService";
import { staffEditModule } from "@/store/dataModules/staffEditModule";
import { classModule } from "@/store/dataModules/classModule";
import dayjs from "dayjs";
import { StaffInfo } from "chaild-api/lib";
import ApiResource from "@/api/ApiResource";
import ApiStaff from "@/api/ApiStaff";

export default Vue.extend({
  name: "StaffList",

  components: {
    PageTitle,
    // StaffTableContainer,
    draggable,
  },

  async mounted() {
    await this.listStaff();
    await this.listClass();
  },

  watch: {
    updateDialog: {
      handler(val) {
        if (!val) {
          staffEditModule.clearValues();
          this.listStaff();
        }
      },
    },
  },

  data() {
    return {
      loading: false,
      importDialog: false,
      importFile: null as File | null,
    };
  },

  computed: {
    // staff: function () {
    //   return staffModule.staff;
    // },
    staff: {
      get(): StaffInfo[] {
        return staffModule.staff;
      },
      async set(value: StaffInfo[]) {
        if(value) {
          const staffIds = value.map((staff) => staff.userNurseryId);
          staffModule.setStaffIds(staffIds);

          this.loading = true
          const nurseryId = LocalDataService.getNurseryId();
          if (nurseryId) {
            // スタッフ並び替え
            await staffModule.sortStaffs(nurseryId);
            // リスト取得
            await staffModule.listStaff({
              nurseryId: String(nurseryId),
            });
          }
          this.loading = false
        }
      }
    },
    check: {
      get() {
        return staffModule.retireCheck;
      },
      async set(value: boolean){
        staffModule.setCheck(value);
        
        this.loading = true;
        const nurseryId = LocalDataService.getNurseryId();
        if (nurseryId) {
          // リスト取得
          await staffModule.listStaff({
            nurseryId: String(nurseryId),
            check: this.check,
          })
        }
        
        this.loading = false;
      },
    }
  },

  methods: {
    async listStaff() {
      this.loading = true;

      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await staffModule.listStaff({
          nurseryId: String(nurseryId),
        });
      }

      this.loading = false;
    },
    async getStaff(userId: string) {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await staffModule.getStaff({
          nurseryId: String(nurseryId),
          userNurseryId: userId,
        });
      }
    },
    async clickStaff(item: StaffInfo) {
      if (item) {
        staffEditModule.setUserId(String(item.userNurseryId));

        this.$router.push({ path: `/setting/staff/${item.userNurseryId}` });
      }
    },
    async listClass() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await classModule.listClass({
          nurseryId: nurseryId,
          year: dayjs().year(),
        });
      }
    },
    createStaff() {
      this.$router.push({ path: "/setting/staff/new/edit" });
    },
    async importCsv() {
      this.loading = true;
      const nurseryId = LocalDataService.getNurseryId();
      if (this.importFile && nurseryId) {
        // get presigned url
        const resourceResponse = await ApiResource.getPresignedUrl({
          imageType: "nurseryUserCsv",
          nurseryId,
          filename: this.importFile.name,
        }).catch();
        if (resourceResponse) {
          // upload
          const uploadResponse = await ApiResource.uploadFile({
            url: resourceResponse.url,
            file: this.importFile,
          }).catch();
          // import csv
          if (uploadResponse) {
            const importResponse = await ApiStaff.importStaffCsv({
              nurseryId,
              filePath: resourceResponse.filePath,
            }).catch(() => {
              this.loading = false;
              this.importDialog = false;
            });
            if (importResponse) {
              alert("インポートが完了しました");
              this.importDialog = false;
              await this.listStaff();
            }
          }
        }
      }
      this.loading = false;
    },
  },
});
