import ApiClient from '@/api/ApiClient';

export default class ApiInvoice {

  public static async listInvoice(input: {
    nurseryId: number;
    classId?: number;
    month?: number;
    year?: number;
  }) {
    const response = await ApiClient.get(`/invoice/nursery/${input.nurseryId}`, input);
    if (response) {
      return response;
    }
  }

  public static async getInvoice(input: {
    nurseryId: number;
    childId: number;
    month: number;
    year: number;
  }) {
    const response = await ApiClient.get(`/invoice/nursery/${input.nurseryId}/nursery-hours`, input);
    if (response) {
      return response;
    }
  }

  public static async getInvoiceStatus(input: {
    nurseryId: number;
    month: number;
    year: number;
  }) {
    const response = await ApiClient.get(`/invoice/nursery/${input.nurseryId}/status`, input);
    return response;
  }

  public static async updateInvoiceStatus(input: {
    nurseryId: number;
    month: number;
    year: number;
    status: "applied" | "canceled" | "salesSettled" | "invoiceSettled";
  }) {
    const response = await ApiClient.put(`/invoice/nursery/${input.nurseryId}/status`, input);
    return response;
  }

  public static async getFee(input: {
    nurseryId: number;
    classId?: string;
    limit?: number;
    skip?: number;
  }) {
    const response = await ApiClient.get('/fee/search', input);
    return response;
  }

  /**
   * @description
   * 園児の延長保育料金一覧ダウンロード
   */
  public static async downloadInvoiceDetail(input: {
    nurseryId: number;
    childId: number;
    month: number;
    year: number;
  }) {
    const response = await ApiClient.post(`/invoice/nursery/${input.nurseryId}/nursery-hours/download`, input);
    return response;
  }

  /**
   * @description
   * GET: 保護者への請求書ダウンロード
   */
  static async downloadInvoice(input: {
    nurseryId: number;
    childId: number;
    month: number;
    year: number;
  }) {
    return await ApiClient.get(`/invoice/nursery/${input.nurseryId}/download`, input);
  }


}