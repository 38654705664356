import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import {
  NurseryNapAirConditionRequest,
  NurseryNapDetailResponse,
  NurseryNapIndividualResponse,
} from "chaild-api/lib";
import ApiNap, { NapMonitoringData } from "@/api/ApiNap";
import LocalDataService from "@/service/LocalDataService";
import { NurseryUserResponse } from "chaild-api/src/component/nursery/user/types";
import ApiNurseryUser from "@/api/ApiNurseryUser";
import { meModule } from "./meModule";

export const poseOptions = {
  leftToUp: "leftToUp",
  downToUp: "downToUp",
  rightToUp: "rightToUp",
};

export type PoseType = "leftToUp" | "downToUp" | "rightToUp";

// 備考(くしゃみ:sneeze, 咳:cough, 熱:fever, 発汗:sweat, しゃっくり:hiccup, 鼻水:runnyNose, 呼吸の乱れ:breathDisorder,嘔吐:vomiting)
export const additionalNoteOptions = {
  sneeze: "sneeze",
  cough: "cough",
  fever: "fever",
  sweat: "sweat",
  hiccup: "hiccup",
  runnyNose: "runnyNose",
  breathDisorder: "breathDisorder",
  vomiting: "vomiting",
};

export type NapDataNote =
  | "sneeze"
  | "cough"
  | "fever"
  | "sweat"
  | "hiccup"
  | "runnyNose"
  | "breathDisorder"
  | "vomiting";

// 状態(hug:抱っこ, sleepIn:就寝, getUp:起床, faceUp:仰向け, faceDown:うつ伏せ, faceRight:右向き, faceLeft:左向き)
export const napDataStatusOptions = {
  hug: "hug",
  sleepIn: "sleepIn",
  getUp: "getUp",
  faceUp: "faceUp",
  faceDown: "faceDown",
  faceRight: "faceRight",
  faceLeft: "faceLeft",
};

export type NapDataStatus =
  | "hug"
  | "sleepIn"
  | "getUp"
  | "faceUp"
  | "faceDown"
  | "faceRight"
  | "faceLeft";
export interface NapDetailData {
  childId: number;
  time: string;
  staffId: number;

  posturalChanged: PoseType | null;
  status: NapDataStatus | null;
  additionalNote: NapDataNote[];
  textComment: string | null;
}
export interface NapDetailState {
  napId: number | null;
  nap: NurseryNapDetailResponse | null;
  napData: NapMonitoringData[];

  staffs: NurseryUserResponse[];

  creatingNapDetailData: NapDetailData | null;
  editingNapDetailData: NapDetailData | null;
}

@Module({ dynamic: true, store, name: "nap-detail", namespaced: true })
class NapDetailModule extends VuexModule implements NapDetailState {
  public napId: number | null = null;
  public nap: NurseryNapDetailResponse | null = null;
  public napData: NapMonitoringData[] = [];

  public staffs: NurseryUserResponse[] = [];

  public creatingNapDetailData: NapDetailData | null = null;
  public editingNapDetailData: NapDetailData | null = null;

  @Mutation
  public setNapId(napId: number) {
    this.napId = napId;
  }

  @Action
  public async getNap() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.napId) {
      const response = await ApiNap.getNap({
        nurseryId,
        napId: this.napId,
      });

      if (response) {
        this.setNap(response);
      }
    }
  }

  @Mutation
  public setNap(nap: NurseryNapDetailResponse) {
    this.nap = nap;
  }

  @Action
  public async updateNap(airConditions?: NurseryNapAirConditionRequest[]) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.nap) {
      const updated = await ApiNap.updateNap({
        nurseryId,
        napId: this.nap.napId,
        comment: this.nap.comment || undefined,
        recorderId: this.nap.recorderId,
        weather: this.nap.weather || undefined,
        airConditions: airConditions,
      });

      if (updated) {
        this.setNap(updated);
      }
    }
  }

  @Action
  public async updateNapStatus(status: string) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.nap) {
      const updated = await ApiNap.updateNapStatus({
        nurseryId,
        napId: this.nap.napId,
        status,
      });

      if (updated) {
        this.setNap(updated);
      }
    }
  }

  @Action
  public async listNapData() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.napId) {
      const response = await ApiNap.listNapData({
        nurseryId,
        napId: this.napId,
      });

      if (response) {
        this.setNapData(response);
      }
    }
  }

  @Mutation
  public setNapData(napdata: NapMonitoringData[]) {
    this.napData = napdata;
  }

  @Action
  public async listStaffs(input: {
    date?: string;
    diaryId?: number;
    diaryType?: 'class' | 'school' | 'hours' | 'nap';
  }) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiNurseryUser.listStaffs(
        nurseryId,
        input,
      );
      if (response) {
        this.setStaffs(response);
      }
    }
  }

  @Mutation
  public setStaffs(staffs: NurseryUserResponse[]) {
    this.staffs = staffs;
  }

  @Mutation
  public setCreatingNapDetailData(napDetailData: NapDetailData | null) {
    this.creatingNapDetailData = napDetailData;
  }

  @Mutation
  public setEditingNapDetailData(napDetailData: NapDetailData | null) {
    this.editingNapDetailData = napDetailData;
  }

  @Action
  public async createData() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && meModule.me && this.creatingNapDetailData && this.nap) {
      const staffId = this.nap.recorderId;

      const params: {
        posturalChanged?: string;
        status?: string;
        staffId: number;
        additionalNote?: string[];
        textComment?: string;
      } = { staffId };

      if (this.creatingNapDetailData.posturalChanged) {
        params.posturalChanged = this.creatingNapDetailData
          .posturalChanged as string;
      }
      if (this.creatingNapDetailData.status) {
        params.status = this.creatingNapDetailData.status as string;
      }
      if (this.creatingNapDetailData.additionalNote.length > 0) {
        params.additionalNote = this.creatingNapDetailData
          .additionalNote as string[];
      }
      if (this.creatingNapDetailData.textComment) {
        params.textComment = this.creatingNapDetailData.textComment;
      }

      const createResponse = await ApiNap.createNapData({
        nurseryId,
        childId: this.creatingNapDetailData.childId,
        napId: this.nap.napId,
        time: this.creatingNapDetailData.time.replace(":", ""),
        params,
      });

      if (createResponse) {
        this.updateIndividualData(createResponse);
      }

      this.setCreatingNapDetailData(null);
    }
  }

  @Action
  public async editData() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && meModule.me && this.editingNapDetailData && this.nap) {
      const staffId = this.nap.recorderId;

      const params: {
        posturalChanged?: string;
        status?: string;
        staffId: number;
        additionalNote?: string[];
        textComment?: string;
      } = { staffId };

      if (this.editingNapDetailData.posturalChanged) {
        params.posturalChanged = this.editingNapDetailData
          .posturalChanged as string;
      }
      if (this.editingNapDetailData.status) {
        params.status = this.editingNapDetailData.status as string;
      }
      if (this.editingNapDetailData.additionalNote.length > 0) {
        params.additionalNote = this.editingNapDetailData
          .additionalNote as string[];
      }
      if (this.editingNapDetailData.textComment) {
        params.textComment = this.editingNapDetailData.textComment;
      }

      const updateResponse = await ApiNap.editNapData({
        nurseryId,
        childId: this.editingNapDetailData.childId,
        napId: this.nap.napId,
        time: this.editingNapDetailData.time.replace(":", ""),
        params,
      });

      if (updateResponse) {
        this.updateIndividualData(updateResponse);
      }

      this.setEditingNapDetailData(null);
    }
  }

  @Action
  public async deleteData() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.editingNapDetailData && this.nap) {
      const response = await ApiNap.deleteNapData({
        nurseryId,
        childId: this.editingNapDetailData.childId,
        napId: this.nap.napId,
        time: this.editingNapDetailData.time,
      });

      if (response) {
        this.removeIndividualData({
          childId: this.editingNapDetailData.childId,
          time: this.editingNapDetailData.time,
        });
        this.setEditingNapDetailData(null);
      } else {
        alert("削除に失敗しました");
      }
    }
  }

  @Mutation
  public updateIndividualData(data: NurseryNapIndividualResponse) {
    if (this.napData) {
      this.napData = this.napData.map((napData) => {
        if (napData.child.childId === data.child.childId) {
          let shouldPush = true;
          const overviews = napData.overviews.map((ov) => {
            if (ov.time === data.time) {
              shouldPush = false;
              return {
                time: data.time,
                posturalChanged: data.posturalChanged,
                status: data.status,
                additionalNote: data.additionalNote,
                staff: data.staff,
              };
            }
            return ov;
          });

          if (shouldPush) {
            overviews.push({
              time: data.time,
              posturalChanged: data.posturalChanged,
              status: data.status,
              additionalNote: data.additionalNote,
              staff: data.staff,
            });
          }

          return {
            child: napData.child,
            overviews,
          };
        }
        return napData;
      });
    }
  }

  @Mutation
  public removeIndividualData(payload: { childId: number; time: string }) {
    if (this.napData) {
      const time = payload.time.replace(":", "");
      this.napData = this.napData.map((napData) => {
        if (napData.child.childId === payload.childId) {
          const newValue = { ...napData };
          newValue.overviews = newValue.overviews.filter(
            (ov) => ov.time !== time
          );
          return newValue;
        }
        return napData;
      });
    }
  }
}

export const napDetailModule = getModule(NapDetailModule);
