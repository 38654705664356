
import Vue from 'vue';

import { meModule } from '@/store/dataModules/meModule';
import { napSensorModule } from '@/store/dataModules/napSensorModule';
import { MeResponse, NurseryNapDetailResponse, NurseryNapSensorLatest } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';

import NapSensorCard from '@/components/molecules/NapSensorCard.vue';
import dayjs from 'dayjs';
import { nurseryUserModule } from '@/store/dataModules/nurseryUserModule';

interface DataType {
  isLoading: boolean;

  timer: number | null;
  isIntervalCheckOn: boolean;
  isConfirming: boolean;

  recorderId: number | null;

  updatingChildId: number | null;

  alertTimerId: number | null;

  initialDialog: boolean;
}

export default Vue.extend({

  components: {
    NapSensorCard,
  },

  data: (): DataType => {
    return {
      isLoading: false,

      timer: null,
      isIntervalCheckOn: true,
      isConfirming: false,

      recorderId: null,

      updatingChildId: null,

      alertTimerId: null,
      initialDialog: false,
    };
  },

  computed: {
    me() {
      return meModule.me;
    },
    staffOptions() {
      return nurseryUserModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`
        }
      });
    },
    latestData() {
      return napSensorModule.latest;
    },
    nap(): NurseryNapDetailResponse | null {
      return napSensorModule.nap;
    },
    intervalCheckTimes(): string[] {
      return napSensorModule.intervalCheckTimes;
    },
    intervalOptions() {
      return [
        { value: 0, label: '5分' },
        { value: 1, label: '10分' },
        { value: 2, label: '15分' },
        { value: 3, label: '30分' },
        { value: 4, label: '60分' },
      ]
    },
    intervalMinute() {
      if (this.nap) {
        switch (this.nap.interval) {
          case 0:
            return 5;
          case 1:
            return 10;
          case 2:
            return 15;
          case 3:
            return 30;
          case 4:
            return 60;
          default:
            break;
        }
      }
      return -1;
    },
    intervalLabel(): string {
      if (this.nap) {
        const find = this.intervalOptions.find((op) => op.value === this.nap?.interval);
        if (find) {
          return find.label;
        }
      }
      return '';
    },
    sensorData(): NurseryNapSensorLatest[] {
      if (this.latestData) {
        return this.latestData.data;
      }
      return [];
    },
    isAlertOn: {
      get() {
        return napSensorModule.isAlertOn;
      },
      set(value: boolean) {
        if (value) {
          napSensorModule.ringAlert();
        }
        napSensorModule.setIsAlertOn(value);
      }
    },
    alertingChildrenIds() {
      return napSensorModule.alertingChildrenIds;
    }
  },

  methods: {
    getStatus(data: NurseryNapSensorLatest) {
      if (!data.isStart) {
        return 'standby';
      } else if (!data.isSleeping) {
        if (
          data.posture === -6 ||
          data.posture === 6 || 
          data.isAlert
        ) {
          return 'awake-danger';
        }
        return 'awake';
      } else {
        if (!data.posture || data.posture === 0) {
          return 'sleep-up';
        }
        if (
          data.posture === -6 ||
          data.posture === 6
        ) {
          return 'sleep-danger';
        }
        if (data.posture < 0) {
          return 'sleep-right';
        }
        if (data.posture > 0) {
          return 'sleep-left';
        }
        return 'awake';
      }
    },
    getImage(data: NurseryNapSensorLatest) {
      const status = this.getStatus(data);

      if (status === 'standby') {
        return '/awake.png';
        // return null;
      }
      if (status.endsWith('danger')) {
        return '/danger.png';
      } else if (status === 'awake') {
        return '/awake.png';
      } else if (status === 'sleep-up') {
        return '/sleep.png';
      } else if (status === 'sleep-right') {
        return '/sleep-right.png';
      } else if (status === 'sleep-left') {
        return '/sleep-left.png';
      }
      return null;
    },
    intervalCheck() {
      if (!this.isIntervalCheckOn || !this.nap) {
        return;
      }

      const now = parseInt(dayjs().format('mm'));
      if (now % this.intervalMinute === 0) {
        this.playInlineTimer();
        const timecode = dayjs().toISOString();
        napSensorModule.addIntervalCheckTime(timecode);
      }
    },
    async confirmIntervalCheck() {
      if (this.recorderId) {
        this.isConfirming = true;
        await napSensorModule.confirmOldestIntervalCheck(this.recorderId);
        this.isConfirming = false;
      }
    },
    clearIntervalCheck () {
      napSensorModule.clearIntervalCheckTime()
    },
    formatTime(time: string) {
      return dayjs(time).format('hh:mm');
    },
    addInvervalCheck() {
      const time = dayjs().toISOString();
      napSensorModule.addIntervalCheckTime(time);
    },
    stopAlert(childId: number) {
      napSensorModule.removeAlertingChildId(childId);
    },
    async sleepIn(data: NurseryNapSensorLatest) {
      this.updatingChildId = data.childId;
      await napSensorModule.updateChildNapStatus({
        isSleep: true,
        childId: data.childId
      });
      await napSensorModule.getLatest();
      this.updatingChildId = null;
    },
    async getUp(data: NurseryNapSensorLatest) {
      this.updatingChildId = data.childId;
      await napSensorModule.updateChildNapStatus({
        isSleep: false,
        childId: data.childId
      });
      await napSensorModule.getLatest();
      this.updatingChildId = null;
    },
    handlePusher() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.me) {
        const account = this.me.nurserySchools.find((school) => school.nurseryId === nurseryId);
        if (account) {
          napSensorModule.setStaffId(account.userId);
          napSensorModule.connectPusher();
        }
      }
    },
    closeInitialDialog() {
      this.initialDialog = false;
      // const music = new Audio('/assets/ccs_timer.mp3');
      // music.play();
      this.playInlineSoundsSilent();
    },
    playInlineAlert() {
      const alertEl = this.$refs.alertSound as HTMLAudioElement;
      if (alertEl) {
        alertEl.muted = false;
        alertEl.play();
      }
    },
    playInlineTimer() {
      const timerEl = this.$refs.timerSound as HTMLAudioElement;
      if (timerEl) {
        timerEl.muted = false;
        timerEl.play();
      }
    },
    playInlineSoundsSilent() {
      const alertEl = this.$refs.alertSound as HTMLAudioElement;
      if (alertEl) {
        alertEl.muted = true;
        alertEl.play();
      }

      const timerEl = this.$refs.timerSound as HTMLAudioElement;
      if (timerEl) {
        timerEl.muted = true;
        timerEl.play();
      }
    },
  },

  watch: {
    me: {
      handler(value: MeResponse | null | undefined) {
        this.handlePusher();
      },
      immediate: true,
    },
    alertingChildrenIds: {
      handler(value: number[]) {
        if (value.length > 0) {
          if (!this.alertTimerId) {
            this.alertTimerId = setInterval(() => {
              this.playInlineAlert();
            }, 2000);
          }
        } else {
          if (this.alertTimerId) {
            clearInterval(this.alertTimerId);
            this.alertTimerId = null;
          }
        }
      },
      immediate: true,
    }
  },

  async mounted() {
    const napId = this.$route.params.napId as string;
    if (napId) {
      const parsed = parseInt(napId);
      this.isLoading = true;
      await Promise.all([
        napSensorModule.setNapId(parsed),
        napSensorModule.getLatest(),
        napSensorModule.getNap(),
      ]);
      this.handlePusher();
      this.isLoading = false;

      if (this.nap) {
        this.recorderId = this.nap.recorderId;
      }

      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        if (this.nap) {
          nurseryUserModule.listStaffs({ date: this.nap.date });
        }
        else {
          nurseryUserModule.listStaffs({});
        }
      }
      this.timer = setInterval(this.intervalCheck, 60000);

      this.initialDialog = true;
    }
  },

  beforeDestroy() {
    napSensorModule.disconnect();
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    if (this.alertTimerId) {
      clearInterval(this.alertTimerId);
      this.alertTimerId = null;
    }
  }
})
