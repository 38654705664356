
import Vue from 'vue';

import { foodMenuTestModule } from '@/store/viewModules/food-menu/foodMenuTestModule';

import PageTitle from '@/components/atoms/PageTitle.vue';
import { foodMenusModule } from '@/store/viewModules/food-menu/foodMenusModule';
import { nurseryUserModule } from '@/store/dataModules/nurseryUserModule';
import dayjs from 'dayjs';

interface DataType {
  flavor: number | null;
  texture: number | null;
  freshness: number | null;
  arrangement: number | null;
  color: number | null;
  quantity: number | null;
  hasNoForeignMatter: boolean | null;
  hasProperHeat: boolean | null;
  hasNoOdor: boolean | null;
  hasProperTaste: boolean | null;
  note: string;
  testedBy: number | null;
  testedAt: string | null;

  isCreating: boolean;
  isUpdating: boolean;
}

export default Vue.extend({
  components: {
    PageTitle,
  },

  data: (): DataType => ({
    flavor: 1,
    texture: 1,
    freshness: 1,
    arrangement: 1,
    color: 1,
    quantity: 1,
    hasNoForeignMatter: true,
    hasProperHeat: true,
    hasNoOdor: true,
    hasProperTaste: true,
    note: '',
    testedBy: null,
    testedAt: null,

    isCreating: false,
    isUpdating: false,
  }),

  watch: {
    updatingTestedAt: {
      handler(value: string) {
        nurseryUserModule.listStaffs({ date: value, diaryId: this.testingMeal?.test?.testId, diaryType: 'Test' });
        return
      }
    },
    testedAt: {
      handler(value: string) {
        nurseryUserModule.listStaffs({ date: value, diaryId: this.testingMeal?.test?.testId, diaryType: 'Test' });
        return
      }
    },
  },
  
  computed: {
    isOpen() {
      if (foodMenuTestModule.testingMeal) {
        return true;
      }
      return false;
    },
    testingMeal() {
      return foodMenuTestModule.testingMeal;
    },
    isCreationValid() {
      if (
        this.flavor
        && this.texture
        && this.freshness
        && this.arrangement
        && this.color
        && this.quantity
        && this.hasNoForeignMatter
        && this.hasProperHeat
        && this.hasNoOdor
        && this.hasProperTaste
        && this.testedBy
        && this.testedAt
        && this.note.length > 0
      ) {
        return true;
      }
      return false;
    },
    staffOptions() {
      return nurseryUserModule.staffs.map((staff) => {
        return {
          userId: staff.userId,
          name: `${staff.lastName} ${staff.firstName}`
        }
      });
    },
    updatingTestedAt: {
      get(): string | null {
        if (
          this.testingMeal
          && this.testingMeal.test
          && this.testingMeal.test.testedAt
        ) {
          return dayjs(this.testingMeal.test.testedAt).format('YYYY-MM-DDTHH:mm')
        }
        return null;
      },
      set(datetime: string) {
        if (
          this.testingMeal
          && this.testingMeal.test
        ) {
          this.testingMeal.test.testedAt = datetime
        }
      }
    }
  },

  methods: {
    close() {
      foodMenuTestModule.setTestingMeal(null);
    },
    async createTest() {
      if (
        this.testingMeal
        && this.flavor
        && this.texture
        && this.freshness
        && this.arrangement
        && this.color
        && this.quantity
        && this.hasNoForeignMatter
        && this.hasProperHeat
        && this.hasNoOdor
        && this.hasProperTaste
        && this.testedBy
        && this.testedAt
      ) {
        this.isCreating = true;
        const response = await foodMenuTestModule.createTest({
          planId: this.testingMeal.planId,
          flavor: this.flavor,
          texture: this.texture,
          freshness: this.freshness,
          arrangement: this.arrangement,
          color: this.color,
          quantity: this.quantity,
          hasNoForeignMatter: this.hasNoForeignMatter,
          hasProperHeat: this.hasProperHeat,
          hasNoOdor: this.hasNoOdor,
          hasProperTaste: this.hasProperTaste,
          note: this.note,
          testedBy: this.testedBy,
          testedAt: dayjs(this.testedAt).toISOString(),
        });

        if (response) {
          foodMenusModule.replaceMenu(response);
        }

        this.isCreating = false;
        this.close();
      }
    },
    async updateTest() {
      if (
        this.testingMeal
        && this.testingMeal.test
      ) {
        this.isUpdating = true;
        const response = await foodMenuTestModule.updateTest({
          testId: this.testingMeal.test.testId,
          flavor: this.testingMeal.test.flavor,
          texture: this.testingMeal.test.texture,
          freshness: this.testingMeal.test.freshness,
          arrangement: this.testingMeal.test.arrangement,
          color: this.testingMeal.test.color,
          quantity: this.testingMeal.test.quantity,
          hasNoForeignMatter: this.testingMeal.test.hasNoForeignMatter,
          hasProperHeat: this.testingMeal.test.hasProperHeat,
          hasNoOdor: this.testingMeal.test.hasNoOdor,
          hasProperTaste: this.testingMeal.test.hasProperTaste,
          note: this.testingMeal.test.note || ' ',
          testedBy: this.testingMeal.test.testedBy.userId,
          testedAt: this.testingMeal.test.testedAt,
        });

        if (response) {
          foodMenusModule.replaceMenu(response);
        }

        this.isUpdating = false;
        this.close();
      }
    },
    async deleteTest() {
      if (
        this.testingMeal
        && this.testingMeal.test
      ) {
        this.isUpdating = true;
        const response = await foodMenuTestModule.deleteTest({
          testId: this.testingMeal.test.testId,
        });

        this.$emit('delete:test');

        this.isUpdating = false;
        this.close();
      }
    }
  },

  mounted() {
    this.testedAt = dayjs().format('YYYY-MM-DDTHH:mm');
    nurseryUserModule.listStaffs({ date: this.testedAt, diaryId: this.testingMeal?.test?.testId, diaryType: 'Test' });
  }
});
