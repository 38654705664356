import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import {
  NurseryChildResponse,
  NurseryClassResponse,
  NurseryNapTemplateResponse,
} from "chaild-api/lib";
import ApiChildren from "@/api/ApiChildren";
import ApiNap from "@/api/ApiNap";
import ApiNapTemplate from "@/api/ApiNapTemplate";
import ApiNurseryUser from "@/api/ApiNurseryUser";
import LocalDataService from "@/service/LocalDataService";
import { NurseryUserResponse } from "chaild-api/src/component/nursery/user/types";
import dayjs from "dayjs";
import ApiClass from "@/api/ApiClass";
import CommonUtil from "@/utils/CommonUtil";

export interface NapCreateState {
  napTemplates: NurseryNapTemplateResponse[];
  staffs: NurseryUserResponse[];
  selectedChildren: NurseryChildResponse[];
  childrenOptions: NurseryChildResponse[];

  date: string;
  recorderId: number | null;

  classIds: number[];
  ages: number[];
  interval: number;

  classes: NurseryClassResponse[];
}

@Module({ dynamic: true, store, name: "nap-create", namespaced: true })
class NapCreateModule extends VuexModule implements NapCreateState {
  public napTemplates: NurseryNapTemplateResponse[] = [];
  public staffs: NurseryUserResponse[] = [];
  public selectedChildren: NurseryChildResponse[] = [];
  public childrenOptions: NurseryChildResponse[] = [];
  public classes: NurseryClassResponse[] = [];

  public date: string = dayjs().format("YYYY-MM-DD");
  public recorderId: number | null = null;

  public classIds: number[] = [];
  public ages: number[] = [];
  public interval = 0;

  @Action
  public async listNapTemplates() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiNapTemplate.listNapTemplates({
        nurseryId,
      });
      if (response) {
        this.setNapTemplates(response);
      }
    }
  }

  @Mutation
  public setNapTemplates(templates: NurseryNapTemplateResponse[]) {
    this.napTemplates = templates;
  }

  @Action
  public async listStaffs(date: string) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiNurseryUser.listStaffs(
        nurseryId,
        { date: date }
      );
      if (response) {
        this.setStaffs(response);
      }
    }
  }

  @Mutation
  public setStaffs(staffs: NurseryUserResponse[]) {
    this.staffs = staffs;
  }

  @Action
  public async addChildrenWithClassIds(classId: number) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const response = await ApiChildren.listChildren({ classId, nurseryId });
      const activeChildren = response.filter((child) => {
        if (child.quitDate) {
          const now = dayjs();
          const quitDate = dayjs(child.quitDate);
          const diff = now.diff(quitDate);
          if (diff > 0) {
            return false;
          }
        }
        return true;
      });
      if (response) {
        this.addChildren(activeChildren);
      }
    }
  }

  @Mutation
  public addChildren(children: NurseryChildResponse[]) {
    // const currentChildren = this.selectedChildren.concat(this.childrenOptions);
    // const newValues: NurseryChildResponse[] = [];
    // children.forEach((newChild) => {
    //   const find = currentChildren.find((c) => c.childId === newChild.childId);
    //   if (!find) {
    //     newValues.push(newChild);
    //   }
    // })
    // this.selectedChildren = this.selectedChildren.concat(newValues);

    const currentSelected = [...this.selectedChildren];
    const newSelected: NurseryChildResponse[] = [];

    children.forEach((child) => {
      const findFromSelected = currentSelected.find(
        (c) => c.childId === child.childId
      );
      if (!findFromSelected) {
        newSelected.push(child);
      }
    });
    this.selectedChildren = this.selectedChildren.concat(newSelected);

    const childrenIds = children.map((c) => c.childId);
    this.childrenOptions = this.childrenOptions.filter((c) => {
      if (childrenIds.indexOf(c.childId) >= 0) {
        return false;
      }
      return true;
    });
  }

  @Action
  public removeChildrenWithClassId(classId: number) {
    const children = this.selectedChildren.filter((child) => {
      const findClass = child.nurseryClasses.find((c) => c.classId === classId);
      if (findClass) {
        this.addOptionChild(child);
        return false;
      }
      return true;
    });

    this.setSelectedChildren(children);
  }

  @Mutation
  public setSelectedChildren(children: NurseryChildResponse[]) {
    this.selectedChildren = children;
  }

  @Mutation
  public addSelectedChildren(child: NurseryChildResponse) {
    this.selectedChildren.push(child);

    this.childrenOptions = this.childrenOptions.filter(
      (co) => co.childId !== child.childId
    );
  }

  @Action
  public removeChild(childId: number) {
    const children = this.selectedChildren.filter((child) => {
      if (child.childId !== childId) {
        return true;
      }
      this.addOptionChild(child);
      return false;
    });
    this.setSelectedChildren(children);
  }

  @Action
  public selectChild(child: NurseryChildResponse) {
    this.addSelectedChildren(child);
    this.removeChild(child.childId);
    // if (child) {
    //   this.addSelectedChildren(child);
    // }
  }

  @Mutation
  public addOptionChild(child: NurseryChildResponse) {
    this.childrenOptions.push(child);
  }

  @Action
  public async createNap() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.recorderId) {
      const response = await ApiNap.createNap({
        nurseryId,
        date: this.date,
        recorderId: this.recorderId,
        classIds: this.classIds,
        ages: this.ages,
        interval: this.interval,
        childIds: this.selectedChildren.map((child) => child.childId),
      });

      return response || null;
    }
  }

  @Action
  public async createNapTemplate(title: string) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId && this.recorderId) {
      await ApiNap.createNapTemplate({
        nurseryId,
        templateTitle: title,
        recorderId: this.recorderId,
        classIds: this.classIds,
        ages: this.ages,
        interval: this.interval,
        childIds: this.selectedChildren.map((child) => child.childId),
      });
    }
  }

  @Action
  public async deleteNapTemplate(templateId: number) {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      await ApiNap.deleteNapTemplate({
        nurseryId,
        napTemplateId: templateId,
      });
    }
  }

  // ==========

  @Mutation
  public setDate(date: string) {
    this.date = date;
  }

  @Mutation
  public setRecorderId(id: number | null) {
    this.recorderId = id;
  }

  @Mutation
  public setClassIds(ids: number[]) {
    this.classIds = ids;
  }

  @Mutation
  public setAges(ages: number[]) {
    this.ages = ages;
  }

  @Mutation
  public setInterval(interval: number) {
    this.interval = interval;
  }

  // ======
  @Action({ rawError: true })
  public async listClass() {
    const nurseryId = LocalDataService.getNurseryId();
    if (nurseryId) {
      const year = CommonUtil.getFiscalYear(this.date);
      const response = await ApiClass.listClass({
        nurseryId,
        year,
      });
      this.setClasses(response);
      if (response) {
        return response;
      }
    }
  }

  @Mutation
  public setClasses(value: NurseryClassResponse[]) {
    this.classes = value;
  }
}

export const napCreateModule = getModule(NapCreateModule);
