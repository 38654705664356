import ApiClient from "@/api/ApiClient";
import { ShiftUsersResponse } from "chaild-api/lib";

export default class ApiStaff {
  // 保育士

  public static async listStaff(input: { nurseryId: string; check?: boolean }) {
    const response = await ApiClient.get("/admin/staff", {
      nurseryId: input.nurseryId,
      check: input.check,
    });
    return response;
  }

  public static async createStaff(input: {
    nurseryId: string;
    category: "general" | "help" | string;
    firstName: string;
    lastName: string;
    firstNameKana: string;
    lastNameKana: string;
    isDirector: boolean;
    isChief: boolean;
    isAdmin: boolean;
    email: string;
    staffCode1?: string;
    staffCode2?: string;
    birth: string;
    gender: string;
    address?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    joinDate: string;
    assignDate: string;
    retireDate?: string;
    retireReason?: "transfer" | "retire" | "die";
    retireReasonComment?: string;
    classesInCharge: number[];
    comment?: string;
  }) {
    const response = await ApiClient.post(
      `/admin/staff?nurseryId=${input.nurseryId}`,
      {
        category: input.category,
        firstName: input.firstName,
        lastName: input.lastName,
        firstNameKana: input.firstNameKana,
        lastNameKana: input.lastNameKana,
        isDirector: input.isDirector,
        isChief: input.isChief,
        isAdmin: input.isAdmin,
        email: input.email,
        staffCode1: input.staffCode1,
        staffCode2: input.staffCode2,
        birth: input.birth,
        gender: input.gender,
        address: input.address,
        phoneNumber1: input.phoneNumber1,
        phoneNumber2: input.phoneNumber2,
        joinDate: input.joinDate,
        assignDate: input.assignDate,
        retireDate: input.retireDate,
        retireReason: input.retireReason,
        retireReasonComment: input.retireReasonComment,
        classIdsInCharge: input.classesInCharge, // warn: classes or classIds
        comment: input.comment,
      }
    );
    return response;
  }

  public static async deleteStaff(input: {
    userNurseryId: string;
    nurseryId: string;
  }) {
    const response = await ApiClient.delete(
      `/admin/staff/${input.userNurseryId}`,
      {
        nurseryId: input.nurseryId,
      }
    );
    return response;
  }

  public static async getStaff(input: {
    userNurseryId: string;
    nurseryId: string;
  }) {
    const response = await ApiClient.get(
      `/admin/staff/${input.userNurseryId}`,
      {
        nurseryId: input.nurseryId,
      }
    );
    return response;
  }

  public static async sortStaffs({
    nurseryId,
    userIds,
  }: {
    nurseryId: number;
    userIds: number[];
  }): Promise<ShiftUsersResponse | undefined> {
    const response = await ApiClient.post(`/shift-pattern/users`, {
        nurseryId,
        userIds,
      });
    return response;
  }

  public static async updateStaff(input: {
    nurseryId: string;
    userNurseryId: string;
    category?: "general" | "help" | string;
    firstName?: string;
    lastName?: string;
    firstNameKana?: string;
    lastNameKana?: string;
    isDirector?: boolean;
    isChief?: boolean;
    isAdmin?: boolean;
    staffCode1?: string;
    staffCode2?: string;
    birth?: string;
    gender?: string;
    address?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    joinDate?: string;
    assignDate?: string;
    retireDate?: string;
    retireReason?: "transfer" | "retire" | "die";
    retireReasonComment?: string;
    classesInCharge?: number[];
    comment?: string;
    email: string;
  }) {
    const response = await ApiClient.put(
      `/admin/staff/${input.userNurseryId}?nurseryId=${input.nurseryId}`,
      {
        category: input.category,
        firstName: input.firstName,
        lastName: input.lastName,
        firstNameKana: input.firstNameKana,
        lastNameKana: input.lastNameKana,
        isDirector: input.isDirector,
        isChief: input.isChief,
        isAdmin: input.isAdmin,
        staffCode1: input.staffCode1,
        staffCode2: input.staffCode2,
        birth: input.birth,
        gender: input.gender,
        address: input.address,
        phoneNumber1: input.phoneNumber1,
        phoneNumber2: input.phoneNumber2,
        joinDate: input.joinDate,
        assignDate: input.assignDate,
        retireDate: input.retireDate,
        retireReason: input.retireReason,
        retireReasonComment: input.retireReasonComment,
        classIdsInCharge: input.classesInCharge,
        comment: input.comment,
        email: input.email,
      }
    );
    return response;
  }

  public static async importStaffCsv(input: {
    nurseryId: number;
    filePath: string;
  }) {
    return await ApiClient.post(
      `/nursery/${input.nurseryId}/user/import`,
      input
    );
  }
}
