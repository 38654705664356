import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import { DailyReportResponse, DiaryResponse } from "chaild-api/lib";
import ApiChildDiary from "@/api/ApiChildDiary";
import ApiChildReport from "@/api/ApiChildReport";
import { CreateDefecationRequest, CreateMealRequest, CreateTemperatureRequest, CreateMilkRequest, UpdateMealRequest, UpdateDefecationRequest, UpdateTemperatureRequest, UpdateMilkRequest } from "@/model/Request";

export interface ChildDiaryDetailState {
  diary: DiaryResponse | null;
  report: DailyReportResponse | null;

  childId: number | null;
  date: string | null;
}

@Module({ dynamic: true, store, name: "child-diary-detail", namespaced: true })
class ChildDiaryDetailModule
  extends VuexModule
  implements ChildDiaryDetailState
{
  public diary: DiaryResponse | null = null;
  public report: DailyReportResponse | null = null;

  public childId: number | null = null;
  public date: string | null = null;

  @Mutation
  public setChildId(childId: number | null) {
    this.childId = childId;
  }

  @Mutation
  public setDate(date: string | null) {
    this.date = date;
  }

  @Action
  public async getDailyReport() {
    if (this.childId && this.date) {
      const response = await ApiChildReport.fetchChildDiary(
        this.childId,
        this.date
      );
      if (response) {
        this.setReport(response);
      }
    }
  }

  @Mutation
  public setReport(report: DailyReportResponse) {
    this.report = report;
  }

  @Action
  public async getDiary() {
    if (this.childId && this.date) {
      const response = await ApiChildDiary.findChildDiary(
        this.childId,
        this.date
      );
      if (response) {
        this.setDiary(response);
      }
    }
  }

  @Mutation
  public setDiary(diary: DiaryResponse | null) {
    this.diary = diary;
  }

  // ＝＝＝＝＝＝＝＝
  // 排便
  // ＝＝＝＝＝＝＝＝

  @Action
  public async saveDefecation(input: CreateDefecationRequest) {
    const response = await ApiChildDiary.saveDefecation(input);
    return response;
  }

  @Action
  public async updateDefecation(input: UpdateDefecationRequest) {
    const response = await ApiChildDiary.updateDefecation(input);
    return response;
  }

  @Action
  public async deleteDefecation(input: { childId: number; defecationId: number }) {
    const response = await ApiChildDiary.deleteDefecation(input);
    return response;
  }

  // ＝＝＝＝＝＝＝＝
  // 体温
  // ＝＝＝＝＝＝＝＝

  @Action
  public async saveTemperature(input: CreateTemperatureRequest) {
    const response = await ApiChildDiary.saveTemperature(input);
    return response;
  }

  @Action
  public async updateTemperature(input: UpdateTemperatureRequest) {
    const response = await ApiChildDiary.updateTemperature(input);
    return response;
  }

  @Action
  public async deleteTemperature(input: { childId: number; temperatureId: number }) {
    const response = await ApiChildDiary.deleteTemperature(input);
    return response;
  }

  // ＝＝＝＝＝＝＝＝
  // ミルク
  // ＝＝＝＝＝＝＝＝

  @Action
  public async saveMilk(input: CreateMilkRequest) {
    const response = await ApiChildDiary.saveMilk(input);
    return response;
  }

  @Action
  public async updateMilk(input: UpdateMilkRequest) {
    const response = await ApiChildDiary.updateMilk(input);
    return response;
  }

  @Action
  public async deleteMilk(input: { childId: number; milkId: number }) {
    const response = await ApiChildDiary.deleteMilk(input);
    return response;
  }

  // ＝＝＝＝＝＝＝＝
  // 喫食
  // ＝＝＝＝＝＝＝＝

  @Action
  public async createMeal(input: CreateMealRequest) {
    const response = await ApiChildDiary.createMeal(input);
    return response;
  }

  @Action
  public async updateMeal(input: UpdateMealRequest) {
    const response = await ApiChildDiary.updateMeal(input);
    return response;
  }

  // ＝＝＝＝＝＝＝＝
  // 活動記録/発達状況
  // ＝＝＝＝＝＝＝＝

  @Action
  public async updateContent(input: {
    childId: number;
    diaryId: number;
    content: string;
  }) {
    const response = await ApiChildDiary.updateContent(input);
    this.setDiary(response);
  }
}

export const childDiaryDetailModule = getModule(ChildDiaryDetailModule);
